export const LINE_COLORS = [
  "#3b82f6",
  "#10b981",
  "#f59e0b",
  "#ef4444",
  "#8b5cf6",
  "#ec4899",
  "#14b8a6",
  "#f97316",
  "#6366f1",
  "#84cc16",
];

export const EXAMPLE_PROMPTS = [
  "How did Apple's stock reacted to all iphone releases?",
  "Compare AMD and Nvidia and pinpoint the past 4 earning announcement dates",
  "Compare the TSLA and GME during 2020-2022",
  "Show me the latest news and key matrics for Tesla",
];
